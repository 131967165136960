/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(window).load(function() {
          $(".gif").delay(500).hide();
          $(".loader").delay(500).fadeOut(1000);

          // $("html").css("overflow", "auto")
      });

        SocialShareKit.init();


// ********* WINDOWS RELOAD *********
          var ww = $(window).width();
          var limit = 1024;

          function refresh() {
             ww = $(window).width();
             var w =  ww<limit ? (location.reload(true)) :  ( ww>limit ? (location.reload(true)) : ww=limit );
          }

          var tOut;
          $(window).resize(function() {
              var resW = $(window).width();
              clearTimeout(tOut);
              if ( (ww>limit && resW<limit) || (ww<limit && resW>limit) ) {
                  tOut = setTimeout(refresh);
              }
          });



// ********* RESPONSIVE CARROUSEL *********

          if ($(window).width() < 1024) {
            $( ".id_carousel_center" ).toggleClass( "carousel center" );
            $( ".id_carousel_main" ).toggleClass( "carousel__slider js-carousel-main__slider" );
            $( ".id_carousel_main_actu" ).toggleClass( "carousel__slider js-carousel-main__slider" );
            $( ".id_carousel_item" ).toggleClass( "carousel-slide" );


          }
          else {
            $( ".id_carousel_main" ).toggleClass( "grid-x grid-padding-x grid-margin-y" );
            $( ".id_carousel_main_actu" ).toggleClass( "grid-x grid-margin-x grid-margin-y" );
            $( ".id_carousel_item" ).toggleClass( "cell small-offset-1 small-10 medium-offset-3 medium-6 large-offset-0 large-4" );
            $( '.menu-item-has-children' ).append( '' );
          }


// ********* Slick carousel *********
        $carouselMain = $('.center');
        if($carouselMain.length) {
          $carouselMainSlider = $carouselMain.find('.js-carousel-main__slider');
          $carouselMainNav = $carouselMain.find('.js-carousel-main__nav');

          $carouselMainSlider.slick({
            centerMode: true,
            centerPadding: '350px',
            slidesToShow: 1,
            infinite: false,
            dots: true,
            arrows: true,
            prevArrow: '<button type="button" class="carousel-post__prev"><i class="icons8-arrow-right" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" class="carousel-post__next"><i class="icons8-arrow-right" aria-hidden="true"></i></button>',
            responsive: [

              {
                breakpoint: 1650,
                settings: {
                  centerPadding: '200px',
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  centerPadding: '100px',
                }
              },
              {
                breakpoint: 768,
                settings: {
                  arrows: true,
                  centerMode: true,
                  centerPadding: '50px',
                  infinite: false,
                  slidesToShow: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: true,
                  centerMode: true,
                  centerPadding: '20px',
                  infinite: false,
                  slidesToShow: 1
                }
              }
            ]
          });
        }

      //RECETTE SINGLE

        $carouselMain = $('.basic');
        if($carouselMain.length) {
          $carouselMainSlider = $carouselMain.find('.js-carousel-basic__main');
          $carouselMainNav = $carouselMain.find('.js-carousel-basic__nav');

          $carouselMainSlider.slick({
            infinite: true,
            dots: true,
            arrows: true,
            prevArrow: '<button type="button" class="carousel-post__prev"><i class="icons8-arrow-right" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" class="carousel-post__next"><i class="icons8-arrow-right" aria-hidden="true"></i></button>',
          });

        }
      // PRODUCT SINGLE
        $carouselMain = $('.product');
        if($carouselMain.length) {
          $carouselMainSlider = $carouselMain.find('.js-carousel-product__main');
          $carouselMainNav = $carouselMain.find('.js-carousel-product__nav');

          $carouselMainSlider.slick({
            infinite: true,
            dots: true,
            arrows: true,
            centerMode: true,
            centerPadding: '0px',
            prevArrow: '<button type="button" class="carousel-post__prev"><i class="icons8-arrow-right" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" class="carousel-post__next"><i class="icons8-arrow-right" aria-hidden="true"></i></button>',
            asNavFor: $carouselMainNav,
          });
          $carouselMainNav.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            asNavFor: $carouselMainSlider,
            vertical: true,
            responsive: [

              {
                breakpoint: 1024,
                settings: {
                  vertical: false,
                }
              }
            ]
          });
        }

      // PRODUCT SUBCATEGORY

        $carouselMain = $('.product_subcat');
        if($carouselMain.length) {
          $carouselMainSlider = $carouselMain.find('.js-carousel-product_subcat__main');
          $carouselMainNav = $carouselMain.find('.js-carousel-product_subcat__nav');

          $carouselMainSlider.slick({
            infinite: false,
            dots: true,
            slidesToShow: 4,
            arrows: true,
            prevArrow: '<button type="button" class="carousel-post__prev"><i class="icons8-arrow-right" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" class="carousel-post__next"><i class="icons8-arrow-right" aria-hidden="true"></i></button>',
            responsive: [

              {
                breakpoint: 1430,
                settings: {
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 1270,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 785,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 580,
                settings: {
                  slidesToShow: 1,
                }
              }
            ]

          });

        }

        //RECETTE SINGLE

        $carouselMain = $('.game');
        if($carouselMain.length) {
          $carouselMainSlider = $carouselMain.find('.js-carousel-game__main');
          $carouselMainNav = $carouselMain.find('.js-carousel-game__nav');

          $carouselMainSlider.slick({
            infinite: true,
            dots: true,
            arrows: false,
            fade: true,
            autoplay: true,
            autoplaySpeed: 6000,
          });

        }



// ********* Menu *********


          $( '.menu-item-has-children' ).append( '<i class="icons8-plus"></i>' );

          $('.menu-item-has-children').find('> .icons8-plus').click(function (e) {
            var width = $(document).width();

            if (width < 1024 ){
              e.preventDefault();
              $this = $(this);
              var $parent = $this.parent();
              $parent.toggleClass('active');

              $this.toggleClass('icons8-plus').toggleClass('icons8-moins');
            }
          });


          $('.nav-header__link').click(function(e){
            e.preventDefault();
            $('.header-all__nav-primary').addClass('active');
          });

          $('.header-all__nav-close').click(function(e){
            e.preventDefault();
            $('.header-all__nav-primary').removeClass('active');
          });








// ********* Onglets *********

        $('.onglet').click(function(){
          $('.onglet').each(function(){
            $(this).removeClass('active');
          });
          $(this).addClass('active');

          if($(this).attr('data-id')){
            $id = '#' + $(this).attr('data-id');
          }

          if($id) {
            $('.onglet-content').each(function(){
              $(this).removeClass('onglet-content--active');
            });

            $($id).addClass('onglet-content--active');
          }
        });


// *********CONTACT FORM **********

        (function($) {
          /*Brought click function of fileupload button when text field is clicked*/
          $("#uploadtextfield").click(function() {
            $('#fileuploadfield').click()
          });
          /*Brought click function of fileupload button when browse button is clicked*/
          $("#uploadbrowsebutton").click(function() {
            $('#fileuploadfield').click()
          });
          /*To bring the selected file value in text field*/
          $('#fileuploadfield').change(function() {
            $('#uploadtextfield').val($(this).val());
          });
        })(jQuery);


        $(".submit_class").after('<i class="icons8-arrow-right form_submit_button"></i>');
        $(".uploadbrowsebutton").after('<div class="folder"></div>');




// ********* ACF MAP ************


        (function($) {

          /*
          *  new_map
          *
          *  This function will render a Google Map onto the selected jQuery element
          *
          *  @type	function
          *  @date	8/11/2013
          *  @since	4.3.0
          *
          *  @param	$el (jQuery element)
          *  @return	n/a
          */

          function new_map( $el ) {

            // var
            var $markers = $el.find('.marker');


            // vars
            var args = {
              zoom		: 16,
              center		: new google.maps.LatLng(0, 0),
              mapTypeId	: google.maps.MapTypeId.ROADMAP
            };


            // create map
            var map = new google.maps.Map( $el[0], args);


            // add a markers reference
            map.markers = [];


            // add markers
            $markers.each(function(){

                add_marker( $(this), map );

            });


            // center map
            center_map( map );


            // return
            return map;

          }

          /*
          *  add_marker
          *
          *  This function will add a marker to the selected Google Map
          *
          *  @type	function
          *  @date	8/11/2013
          *  @since	4.3.0
          *
          *  @param	$marker (jQuery element)
          *  @param	map (Google Map object)
          *  @return	n/a
          */

          function add_marker( $marker, map ) {

            // var
            var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

            // create marker
            var marker = new google.maps.Marker({
              position	: latlng,
              map			: map
            });

            // add to array
            map.markers.push( marker );

            // if marker contains HTML, add it to an infoWindow
            if( $marker.html() )
            {
              // create info window
              var infowindow = new google.maps.InfoWindow({
                content		: $marker.html()
              });

              // show info window when marker is clicked
              google.maps.event.addListener(marker, 'click', function() {

                infowindow.open( map, marker );

              });
            }

          }

          /*
          *  center_map
          *
          *  This function will center the map, showing all markers attached to this map
          *
          *  @type	function
          *  @date	8/11/2013
          *  @since	4.3.0
          *
          *  @param	map (Google Map object)
          *  @return	n/a
          */

          function center_map( map ) {

            // vars
            var bounds = new google.maps.LatLngBounds();

            // loop through all markers and create bounds
            $.each( map.markers, function( i, marker ){

              var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

              bounds.extend( latlng );

            });

            // only 1 marker?
            if( map.markers.length == 1 )
            {
              // set center of map
                map.setCenter( bounds.getCenter() );
                map.setZoom( 14 );
            }
            else
            {
              // fit to bounds
              map.fitBounds( bounds );
            }

          }

          /*
          *  document ready
          *
          *  This function will render each map when the document is ready (page has loaded)
          *
          *  @type	function
          *  @date	8/11/2013
          *  @since	5.0.0
          *
          *  @param	n/a
          *  @return	n/a
          */
          // global var
          var map = null;

          $(document).ready(function(){

            $('.acf-map').each(function(){

              // create map
              map = new_map( $(this) );

            });

          });

          })(jQuery);



// ********* ACCORDION *************

      $(function(){
        $('.accordion_faq .ask').on('click', function(){
          var id = $(this).attr('data-open');
          $('.accordion_faq .answer[data-id=' + id + ']').slideToggle(400);

          $('.accordion_cat .ask[data-open=' + id + '] i').toggleClass('icons8-arrow-right');
          $('.accordion_cat .ask[data-open=' + id + '] i').toggleClass('icons8-arrow-bottom');

          $('.accordion_ask .ask[data-open=' + id + '] i').toggleClass('icons8-plus');
          $('.accordion_ask .ask[data-open=' + id + '] i').toggleClass('icons8-moins');
        });


      });


// ********* Historique *********

      $('a[href^="#"]').click(function(){
        var the_id = $(this).attr("href");
        if (the_id === '#') {
          return;
        }
        $('html, body').animate({
          scrollTop:$(the_id).offset().top - 300
        }, 'slow');
        return false;
      });


      $(window).scroll(function() {
        var scrollDistance = $(window).scrollTop() + 250;
        // Assign active class to nav links while scolling
        $('.main_story--content_historique--item').each(function(i) {
            if ($(this).position().top <= scrollDistance) {
                $('.main_story--nav_historique a.active').removeClass('active');
                $('.main_story--nav_historique a').eq(i).addClass('active');
            }
        });

      }).scroll();


        // ********* Nav scroll *********
        var isIE = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);

        if(isIE){
          //------
        }
        else{
          var prevScrollpos = window.pageYOffset;
              window.onscroll = function() {
              var currentScrollPos = window.pageYOffset;
              var scrollHeight = $(window).scrollTop();
                if (prevScrollpos > currentScrollPos) {
                  document.getElementById("navbar").style.top = "0";
                } else if ((scrollHeight < 150)){
                  document.getElementById("navbar").style.top = "0";
                }
                else {
                  document.getElementById("navbar").style.top = "-200px";
                }
                prevScrollpos = currentScrollPos;
              };
        }





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // ********* HOME fullpage.js *********
        $(document).ready(function() {
          $('#fullpage').fullpage({
            //options here
            autoScrolling: true,
            licenseKey: 'FC04D54D-2C9B450F-9C8AEBFA-FAA1CD7C',
            navigation: true,
            navigationPosition: 'right',
            navigationTooltips: ['Haut de page', 'Nos produits', 'Nos recettes', "L'actualité", 'Bas de page '],
            animateAnchor: false,
            responsiveWidth: 1024,
          });

          //methods
          $.fn.fullpage.setAllowScrolling(true);
        });

        $(document).ready(function(){
          $(".btn_jeu").click(function(){
            $(".popup-jeu").css("right", "-600px");
          });
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_historique': {
      init: function() {
        // JavaScript to be fired on the about us page




        var mainNav = document.querySelector('#mainNav');
        var footer = document.querySelector('#bloc_more');

        function myFunction(x) {
          if (x.matches) { // If media query matches
            mainNav.style.bottom = '0px';
          } else {
            mainNav.style.bottom = '50px';
          }
        }

        function checkOffset() {
          function getRectTop(el){
            var rect = el.getBoundingClientRect();
            return rect.top;
          }

          if((getRectTop(mainNav) + document.body.scrollTop) + mainNav.offsetHeight >= (getRectTop(footer) + document.body.scrollTop) - 500){
            mainNav.style.position = 'absolute';
            mainNav.style.bottom = '0px';
            mainNav.style.transition = '0.4s';
          }
          if(document.body.scrollTop + window.innerHeight < (getRectTop(footer) + document.body.scrollTop)){
            mainNav.style.position = 'fixed'; // restore when you scroll up



            var x = window.matchMedia("(max-width: 1024px)");
            myFunction(x); // Call listener function at run time
            x.addListener(myFunction);

            mainNav.style.transition = '0.4s';
          }

        }

        document.addEventListener("scroll", function(){
          checkOffset();
        });

        function myFunction(x) {
          if (x.matches) {

            $(window).scroll(function() {
              var scroll = $(window).scrollTop();
                if (scroll >= 500) {
                  $(".main_story--nav_historique").css({"height": "80px"});
                }
                else {
                  $(".main_story--nav_historique").css({"height": "80px"});
                }

            });

          } else {

            $(window).scroll(function() {
              var scroll = $(window).scrollTop();
                if (scroll >= 500) {
                  $(".main_story--nav_historique").css({"height": "80vh"});
                }
                else {
                  $(".main_story--nav_historique").css({"height": "30vh"});
                }

            });
          }
        }
        var x = window.matchMedia("(max-width: 1024px)")




      }

    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function mod_text_slider(tar,delay){

  var container = document.getElementById(tar);
  var count = container.getAttribute('count'),
  delay = delay * 1000;
  container.querySelector(".slide_1").classList.add('show');
  var i = 2;      

  function slideshow_loop() {         
    setTimeout(function() {  
      let hide = container.querySelector(".slide--js.show"),
      show = container.querySelector(".slide_"+i);     
      hide.classList.toggle('show');
      show.classList.toggle('show');
      i++;                  
      if (i <= count) {          
        slideshow_loop();            
      }
      else {
        i = 1;
        slideshow_loop();
      }                    
    },delay)
  }  
  slideshow_loop();                
}